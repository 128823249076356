.web__possibility {
    display: flex;
    flex-direction: row;
}

.web__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.web__possibility-image img {
    max-width: 70%;
    max-height: 80%;
}

.web__possibility-content {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.web__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 50;
    font-size: 2px;
    line-height: 3px;
    color: #71E5FF;
}

.web__possibility-content h4:last-child {
    color: #ff8A71;
}

.web__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 6rem 0;
}

.web__possibility-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    
    margin-bottom: 2rem;

}
.web__possibility-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
@media screen and (max-width: 950px) {
    .web__possibility {
        flex-direction: column;
    }

    .web__possibility-image {
        margin: 1rem 0;
    }

    .web__possibility-image img {
        width: unset;
        height: unset;
    }

    .web__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 6500px) {
    
    .web__possibility-image img {
        width: 100%;
        height: 100%;
    }
}